<template>
	<div class="remind">
		<div class="title">
			{{ $route.meta.title }}
			<i @click="showCourse = !showCourse" class="iconfont icon-question-circle"></i>
		</div>
		<div class="course" v-show="showCourse">
			<p><span class="black">1、当成员的消息记录中有含敏感词/违规行为时，系统将会主动触发违规提醒，企业微信内会收到通知，系统中会保留违规记录。</span></p>
			<p><span class="black">2、当敏感词删除时，系统将会保留已违规的提醒记录。</span></p>
		</div>
		<div class="flex">
			<el-input size="small" style="width: 200px;" v-model="keyword" placeholder="请输入成员"></el-input>
			<el-date-picker
				size="small"
				v-model="date"
				@change="getList()"
				style="width: 260px;margin: 0 10px;"
				type="daterange"
				value-format="yyyy-MM-dd"
				range-separator="-"
				start-placeholder="开始日期"
				end-placeholder="结束日期"
			></el-date-picker>
			<el-button size="small" class="btn-blue" @click="handleSearch">查找</el-button>
			<el-button
				size="small"
				class="btn-white"
				@click="
					() => {
						date = [];
						keyword = '';
					}
				"
			>
				清空
			</el-button>
			<el-button :disabled="selectionList.length === 0" size="small" :class="selectionList.length !== 0 ? 'btn-blue right' : 'btn-opacity right'" @click="handleChangeState">
				批量处理
			</el-button>
		</div>
		<div class="table">
			<el-table
				v-loading="loading"
				:data="tableData"
				style="width: 100%"
				:header-cell-style="{
					color: '#333',
					fontSize: '14px',
					backgroundColor: '#F6F7FB',
					fontWeight: 'normal'
				}"
				@selection-change="handleSelectionChange"
			>
				<el-table-column type="selection"></el-table-column>
				<el-table-column prop="name" label="违规内容">
					<template v-slot="scope">
						<span v-if="scope.row.type == '敏感词'">
							包含敏感词
							<span class="error" style="white-space: nowrap;">"{{ scope.row.name }}"</span>
						</span>
						<span v-else>{{ scope.row.name }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="type" label="违规类型"></el-table-column>
				<el-table-column prop="staff_name" label="违规成员"></el-table-column>
				<el-table-column prop="violation_date" label="触发时间" min-width="120"></el-table-column>
				<el-table-column label="状态">
					<template v-slot="scope">
						{{ scope.row.state == 1 ? '未处理' : scope.row.state == 2 ? '已处理' : '--' }}
					</template>
				</el-table-column>
				<el-table-column label="处理人">
					<template v-slot="scope">
						{{ scope.row.add_user || '--' }}
					</template>
				</el-table-column>
				<el-table-column label="处理时间" min-width="120">
					<template v-slot="scope">
						{{ scope.row.handle_date || '--' }}
					</template>
				</el-table-column>
				<el-table-column label="操作">
					<template v-slot="scope">
						<el-button style="margin-right: 10px;" type="text" @click="handleShowChatList(scope.row)">查看聊天记录</el-button>
						<el-button v-if="scope.row.state == 1" style="margin-left: 0;" type="text" @click="handleShowChatList(scope.row)">处理</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="pagination" v-if="total !== 0">
			<el-pagination
				@size-change="
					pageSize => {
						limit = pageSize;
						page = 1;
						getList();
					}
				"
				@current-change="
					currentPage => {
						page = currentPage;
						getList();
					}
				"
				:current-page="page"
				:page-sizes="[10, 20, 40, 80]"
				:page-size="limit"
				layout="total, sizes, prev, pager, next, jumper"
				:total="total"
			></el-pagination>
		</div>
		<!-- 查看聊天记录 -->
		<el-dialog title="查看聊天记录" :visible.sync="visible" width="600px" :close-on-click-modal="false" :before-close="handleClose">
			<div v-loading="chatLoading">
				<div class="flex">
					<span v-if="row.roomid">{{ row.staff_name || '--' }} 在 {{ row.to_name || '--' }} 的聊天记录</span>
					<span v-else>{{ row.staff_name || '--' }} 与 {{ row.to_name || '--' }} 的聊天记录</span>
					<el-select
						size="small"
						v-model="pageSize"
						@change="
							val => {
								up.page = 1;
								down.page = 1;
								handleShowChatList(row);
							}
						"
						class="right"
						placeholder="请选择"
						style="width: 150px;"
					>
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</div>
				<p class="divider"></p>
				<div class="scroll" @scroll="scrollEvent" style="position: relative;">
					<ul class="list-wrap">
						<li :class="{ list: true, active: item.seq == row.seq }" :ref="item.seq" v-for="item in chatList">
							<img v-if="item.user_avatar" :src="item.user_avatar" alt="" />
							<img v-else src="../../assets/favicon.png" alt="" />
							<div>
								<p>
									<span>{{ item.user_name }}</span>
									<span>{{ item.create_data }}</span>
								</p>
								<div v-if="item.msgtype == 'text'" class="text-wrap">{{ item.content }}</div>
								<div v-else-if="item.msgtype == 'image'" class="text-wrap">
									<el-image
										fit="cover"
										:z-index="9999"
										style="width: 150px; height: 150px"
										:src="item.content"
										@click.stop="$closeImg"
										:preview-src-list="[item.content]"
									></el-image>
								</div>
								<div v-else-if="item.msgtype == 'voice'" style="width: 80%;margin-top: 5px;"><VoicePlayer :url="item.content" :time="item.voice_size" /></div>
								<div class="text-wrap" v-else-if="item.msgtype == 'video'">
									<Video ref="child2" :src="item.content" style="width: 150px; height: 150px; object-fit: cover;cursor: pointer;"></Video>
								</div>
								<div class="text-wrap" v-else-if="item.msgtype == 'file'">
									<template v-if="matchType(item.content) == 'image'">
										<el-image
											fit="cover"
											:z-index="9999"
											style="width: 150px; height: 150px"
											:src="item.content"
											@click.stop="$closeImg"
											:preview-src-list="[item.content]"
										></el-image>
									</template>
									<template v-else-if="matchType(item.content) == 'video'">
										<Video ref="child2" :src="item.content" style="width: 150px; height: 150px; object-fit: cover;cursor: pointer;"></Video>
									</template>
									<template v-else-if="matchType(item.content) == 'audio'">
										<audio controls>
											<source :src="item.content" type="audio/ogg" />
											<source :src="item.content" type="audio/mpeg" />
											<source :src="item.content" type="audio/wav" />
											您的浏览器不支持 audio 元素。
										</audio>
									</template>
									<template v-else>
										<img
											v-if="matchType(item.content) == 'pdf'"
											style="cursor: pointer;margin-top: 10px;"
											@click="downloadFile(item)"
											src="../../assets/file_type/pdf.png"
											alt=""
										/>
										<img
											v-else-if="matchType(item.content) == 'excel'"
											style="cursor: pointer;margin-top: 10px;"
											@click="downloadFile(item)"
											src="../../assets/file_type/excel.png"
											alt=""
										/>
										<img
											v-else-if="matchType(item.content) == 'ppt'"
											style="cursor: pointer;margin-top: 10px;"
											@click="downloadFile(item)"
											src="../../assets/file_type/ppt.png"
											alt=""
										/>
										<img
											v-else-if="matchType(item.content) == 'txt'"
											style="cursor: pointer;margin-top: 10px;"
											@click="downloadFile(item)"
											src="../../assets/file_type/txt.png"
											alt=""
										/>
										<img
											v-else-if="matchType(item.content) == 'word'"
											style="cursor: pointer;margin-top: 10px;"
											@click="downloadFile(item)"
											src="../../assets/file_type/word.png"
											alt=""
										/>
										<img
											v-else-if="matchType(item.content) == 'zip'"
											style="cursor: pointer;margin-top: 10px;"
											@click="downloadFile(item)"
											src="../../assets/file_type/zip.png"
											alt=""
										/>
										<span style="display: block;">{{ item.filename }}</span>
									</template>
								</div>
								<div class="text-wrap" v-else-if="item.msgtype == 'link'" style="color: #0086B3;">{{ item.link_url }}</div>
								<div v-else-if="item.msgtype == 'weapp'" class="weapp">
									<p>
										<img src="../../assets/favicon.png" alt="" />
										<span>{{ item.displayname }}</span>
									</p>
									<h4>{{ item.title }}</h4>
									<div class="desc">{{ item.description }}</div>
									<p class="line"></p>
									<p class="icon">
										<i class="el-icon-link"></i>
										<span>小程序</span>
									</p>
								</div>
								<div class="text-wrap" v-else-if="item.msgtype == 'redpacket'">【红包】</div>
								<div class="text-wrap" v-else-if="item.msgtype == 'external_redpacket'">【外部红包】</div>
								<div class="text-wrap" v-else-if="item.msgtype == 'card'">【名片】</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button v-if="row.state == 1" size="small" class="btn-blue" @click="handleViolation(false)">处 理</el-button>
				<el-button v-else size="small" class="btn-blue" @click="handleClose">关 闭</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import { reqGetRemindList, reqHandleViolation, reqGetChatArchiving } from '@/api/index';
import Video from '@/components/video';
import download from '@/util/download.js';
import VoicePlayer from './VoicePlayer.vue';
export default {
	components: {
		Video,
		VoicePlayer
	},
	data() {
		return {
			showCourse: false,
			keyword: '',
			date: [],
			page: 1,
			limit: 10,
			total: 0,
			tableData: [],
			loading: false,
			selectionList: [],
			visible: false,
			pageSize: 10,
			row: {},
			sort: 1,
			up: {
				list: [],
				page: 1,
				total: 0
			},
			down: {
				list: [],
				page: 1,
				total: 0
			},
			chatList: [],
			chatLoading: false,
			options: [
				{
					value: 10,
					label: '每页10条'
				},
				{
					value: 20,
					label: '每页20条'
				},
				{
					value: 40,
					label: '每页40条'
				},
				{
					value: 80,
					label: '每页80条'
				}
			]
		};
	},
	computed: {
		start_time() {
			if (this.date == null || this.date.length == 0 || !Boolean(this.date)) {
				return '';
			} else {
				return this.date[0];
			}
		},
		end_time() {
			if (this.date == null || this.date.length == 0 || !Boolean(this.date)) {
				return '';
			} else {
				return this.date[1];
			}
		}
	},
	watch: {
		keyword(val) {
			if (!val) {
				this.page = 1;
				this.getList();
			}
		}
	},
	created() {
		this.getList();
	},
	methods: {
		// 下载文件
		downloadFile(data) {
			const url = data.content;
			const fileName = data.filename.substr(0, data.filename.lastIndexOf('.'));
			var x = new XMLHttpRequest();
			x.open('GET', url, true);
			x.responseType = 'blob';
			x.onload = e => {
				download(x.response, fileName);
			};
			x.send();
		},
		handleClose() {
			this.visible = false;
			this.row = {};
			this.pageSize = 10;
			this.up = {
				list: [],
				page: 1,
				total: 0
			};
			this.down = {
				list: [],
				page: 1,
				total: 0
			};
		},
		// 搜索
		handleSearch() {
			if (this.keyword || this.date.length) {
				this.page = 1;
				this.getList();
			}
		},
		//  获取表格数据
		getList() {
			this.loading = true;
			reqGetRemindList({
				page: this.page,
				limit: this.limit,
				keyword: this.keyword,
				start_time: this.start_time,
				end_time: this.end_time
			}).then(res => {
				this.tableData = res.data;
				this.total = res.count || 0;
				this.loading = false;
			});
		},
		//  查看违规聊天记录
		handleShowChatList(row) {
			this.chatLoading = true;
			this.row = row;
			this.visible = true;
			Promise.all([
				new Promise(resolve => {
					reqGetChatArchiving({
						staff_id: this.row.staff_user_id,
						contacts_id: this.row.roomid ? this.row.roomid : this.row.tolist,
						chat_type: this.row.chat_type,
						msgtype: '',
						page: 1,
						limit: this.pageSize,
						seq: this.row.seq,
						sort: 1
					}).then(res => {
						this.down.list = res.data;
						this.down.total = res.count || 0;
						resolve();
					});
				}),
				new Promise(resolve => {
					reqGetChatArchiving({
						staff_id: this.row.staff_user_id,
						contacts_id: this.row.roomid ? this.row.roomid : this.row.tolist,
						chat_type: this.row.chat_type,
						msgtype: '',
						page: 1,
						limit: this.pageSize,
						seq: this.row.seq,
						sort: 2
					}).then(res => {
						this.up.list = res.data;
						this.up.total = res.count || 0;
						resolve();
					});
				})
			]).then(res => {
				let obj = {};
				this.chatList = this.up.list.concat(this.down.list);
				this.chatList = this.chatList.reduce((cur, next) => {
					obj[next.seq] ? '' : (obj[next.seq] = true && cur.push(next));
					return cur;
				}, []);
				this.chatLoading = false;
				this.$nextTick(() => {
					document.querySelector('.scroll').scrollTop = this.$refs[this.row.seq][0].offsetTop;
				});
			});
		},
		// 获取成员聊天列表
		getStaffChatList(oScroll = false) {
			this.chatLoading = true;
			var page;
			if (this.sort == 1) {
				page = this.down.page;
			} else {
				page = this.up.page;
			}
			reqGetChatArchiving({
				staff_id: this.row.staff_user_id,
				contacts_id: this.row.roomid ? this.row.roomid : this.row.tolist,
				chat_type: this.row.chat_type,
				msgtype: '',
				page,
				limit: this.pageSize,
				seq: this.row.seq,
				sort: this.sort
			}).then(res => {
				let obj = {};
				if (this.sort == 1) {
					this.down.list = this.down.list.concat(res.data);
					this.down.total = res.count || 0;
				} else {
					this.up.list = res.data.concat(this.up.list);
					this.up.total = res.count || 0;
				}
				this.chatList = this.up.list.concat(this.down.list);
				this.chatList = this.chatList.reduce((cur, next) => {
					obj[next.seq] ? '' : (obj[next.seq] = true && cur.push(next));
					return cur;
				}, []);
				if (Boolean(oScroll) && this.sort == 2) {
					const startSH = oScroll.scrollHeight;
					this.$nextTick(() => {
						const endSH = oScroll.scrollHeight;
						oScroll.scrollTop = endSH - startSH;
					});
				}
				this.chatLoading = false;
			});
		},
		// 处理违规记录
		handleViolation(row, id) {
			let ids = '';
			row ? (this.row = row) : '';
			if (id !== undefined) {
				ids = id;
			} else {
				ids = this.row.id;
			}
			const add_user = JSON.parse(localStorage.getItem('huankemao_user_info')).phone;
			reqHandleViolation({
				id: ids,
				add_user
			}).then(res => {
				this.$message.success(res.msg);
				this.visible = false;
				this.row = {};
				this.getList();
			});
		},
		// 批量处理违规记录
		handleChangeState() {
			let id = [];
			this.selectionList.forEach(item => {
				id.push(item.id);
			});
			this.handleViolation(false, id.join(','));
		},
		handleSelectionChange(list) {
			this.selectionList = list.filter(val => {
				return val.state == 1;
			});
		},
		scrollEvent(e) {
			const oScroll = e.target;
			if (oScroll.scrollTop <= 100 && !this.chatLoading) {
				this.sort = 2;
				if (this.up.list.length < this.up.total || this.up.total == 0) {
					this.up.page++;
					this.getStaffChatList(oScroll);
				} else {
					return;
				}
			} else if (oScroll.scrollTop + oScroll.clientHeight + 100 >= oScroll.scrollHeight && !this.chatLoading) {
				this.sort = 1;
				if (this.down.list.length < this.down.total || this.down.total == 0) {
					this.down.page++;
					this.getStaffChatList(oScroll);
				} else {
					return;
				}
			}
		}
	}
};
</script>

<style lang="less" scoped>
.remind {
	width: 100%;
	position: relative;
	overflow: hidden;

	.flex {
		display: flex;
		align-items: center;
	}

	.divider {
		width: 100%;
		height: 1px;
		background-color: #f2f2f2;
		margin: 10px 0 20px;
	}

	.list-wrap {
		display: flex;
		flex-direction: column;

		.list {
			width: calc(100% - 20px);
			display: flex;
			padding: 20px 10px;
			border-bottom: 1px solid #f0f2f5;

			> img {
				width: 42px;
				height: 42px;
				margin-right: 10px;
			}

			&.active {
				color: #fff;
				background-color: #f56c6c;
			}

			> div {
				width: 100%;
				display: flex;
				flex-direction: column;

				> p {
					display: flex;
					align-items: center;
					justify-content: space-between;
					> span {
						font-size: 12px;
					}
				}

				.text-wrap {
					line-height: 1.7;
					font-size: 14px;
					color: #333;
					border-radius: 5px;
					padding: 5px 0;
					margin-top: 5px;

					> img {
						width: 42px;
						height: 48px;
					}
				}
			}
		}
	}

	> div + div {
		margin-top: 15px;
	}
	.weapp {
		max-width: 210px;
		padding: 10px 10px 5px;
		border: 1px solid rgba(0, 0, 0, 0.1);
		border-radius: 5px;
		background-color: #fff;
		margin-top: 5px;
		> p {
			display: flex;
			align-items: center;
			> img {
				width: 20px;
				height: 20px;
				border-radius: 50%;
				object-fit: cover;
				margin-right: 10px;
			}
			> span {
				font-size: 12px;
				color: #aaa;
			}
		}
		> h4 {
			font-weight: 400;
			margin: 10px 0;
			line-height: 1.5;
		}
		.desc {
			background-color: #f0f2f5;
			padding: 20px 15px;
			height: 100px;
			font-size: 16px;
			font-weight: bold;
		}
		.line {
			height: 1px;
			background-color: #eee;
			margin: 10px 0 5px;
		}
		.icon {
			display: flex;
			align-items: center;
			font-size: 12px;
			i {
				margin-right: 5px;
				font-size: 14px;
			}
		}
	}
}
</style>
